// OBJECT.CTA

.o-cta__body {
	padding: $bsu $bsu $bsu $lsu;
	@include bp("medium") {
		padding: $lsu $lsu $lsu $hsu;
	}
	@include brand-bg;
	margin: $bsu 0;

	position: relative;
	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: $lsu;
		@include bp("medium") {
			left: $hsu;
		}
		width: 150px;
		border-top: 5px solid $red;
	}

	> *:last-child {
		margin: 0;
	}
}

.o-cta__heading {
	@include font-28b;
	margin-bottom: 0.7em;
	color: $white;
}

.o-cta__text {
	@include font-16;
	&, a {
		color: $white;
	}
	a {
		text-decoration: underline;
	}
}