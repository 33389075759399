$icomoon-font-family: "font-icons" !default;
$icomoon-font-path: "fonts" !default;

$iconf-enlarge: "\e92c";
$iconf-home: "\e918";
$iconf-search: "\e919";
$iconf-arrow-up: "\e904";
$iconf-arrow-down: "\e903";
$iconf-arrow-left: "\e905";
$iconf-arrow-right: "\e906";
$iconf-close: "\e907";
$iconf-minus: "\e908";
$iconf-plus: "\e909";
$iconf-tick: "\e90a";
$iconf-link: "\e90d";
$iconf-external: "\e90c";
$iconf-download: "\e90b";
$iconf-photo: "\e90e";
$iconf-camera: "\e90f";
$iconf-video: "\e910";
$iconf-play: "\e911";
$iconf-email: "\e912";
$iconf-print: "\e914";
$iconf-phone: "\e91b";
$iconf-fax: "\e913";
$iconf-address: "\e916";
$iconf-google-maps: "\e915";
$iconf-calendar: "\e917";
$iconf-share: "\e91c";
$iconf-facebook: "\e922";
$iconf-twitter: "\e91f";
$iconf-youtube: "\e924";
$iconf-flickr: "\e920";
$iconf-pinterest: "\e921";
$iconf-instagram: "\e902";
$iconf-linkedin: "\e923";
$iconf-weibo: "\e928";
$iconf-wechat: "\e900";
$iconf-youku: "\e927";
$iconf-snapchat: "\e926";
$iconf-blogger: "\e925";
$iconf-tumblr: "\e91e";
$iconf-tripadvisor: "\e91d";
$iconf-web: "\e91a";
$iconf-chat: "\e017";
$iconf-filter: "\e929";
$iconf-reload: "\e92a";
$iconf-sort: "\e92b";

