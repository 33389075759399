﻿// CONTROL.PODS


// IMAGE POD
.c-pod__figure {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;

	> img {
		position: absolute;
		left: 50%;
		top: 50%;
		width: auto;
		min-width: 101%;
		min-height: 101%;
		transform: translate(-50%, -50%);
		transition: transform 0.2s ease-in-out;
	}

	&:hover,
	&:focus {
		> img {
			transform: translate(-50%, -50%) scale(1.05);
		}
	}
}

.c-pod__body {
	background-color: rgba($black, 0.35);
	transition: background-color 0.2s ease-in-out;
}
.c-pod__figure {
	&:hover,
	&:focus {
		.c-pod__body {
			background-color: transparent;
		}
	}
}

.c-pod__text {
	max-height: 0;
	overflow: hidden;
	margin: 0;
	transition: 0.4s ease;
	transition-property: max-height;
}
.c-pod-hover,
.c-pod__figure:focus {
	.c-pod__text {
		max-height: 200px;
	}
}

// NO IMAGE POD
.c-pod-noimage {
	background: $brand;
}

a.c-pod-noimage {
	transition: background-color 0.2s ease-in-out;

	&:hover {
		background: darken($brand, 10);
	}
}

.c-pod-noimage__body {
	padding: $bsu;
	color: $white;
}
