﻿// COMPONENT.BREADCRUMB

.c-breadcrumb {
	margin-top: $lsu;
	margin-bottom: $lsu;
}

.c-breadcrumb__link {
	@include link-brand;
	@include font-12;
}

.c-breadcrumb__current {
	@include link-plain;
	@include font-12;
}