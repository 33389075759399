﻿// COMPONENT.SEARCH-RESULT

.c-search-result {}

.c-search-result__body {
}

.c-search-result__heading {
	@include font-24b;
	display: block;
	margin-bottom: $ssu;
	color: $body-color;
}

.c-search-result__url {
	@include font-12;
	@include link-brand;
	margin: $ssu 0;
}

.c-search-result__meta {
	@include font-12;
	display: block;
	margin: $ssu 0;
	color: $body-color;
}

.c-search-result__category {
	display: inline-block;
	padding: 0.5em 1em;
	margin-left: $ssu;
	color: $white;
	background-color: $brand;
}

.c-search-result__text {
	@include font-16;
	display: block;
	color: $body-color;

	&[draggable="false"] {
		display: inline;
	}
}