﻿// CONTROL.MEMBER-LOGIN

.c-member-login {
	width: 100%;
	max-width: 400px;
	margin-right: auto;
	margin-left: auto;
}

.c-member-login__heading {
	text-align: center;
}

.c-member-login__error {
	padding: $ssu;
	border: 1px solid $error;
	margin-top: $bsu;
	color: $error;
	background: $error--light;
}
