﻿// CONTROL.IMAGE-FEATURE

.c-image-feature {
	padding: $bsu;
	background-color: $grey--lightest;

	@include bp('medium') {
		@include flex-extend;
		align-items: center;
		padding: 0;
	}
}

.c-image-feature--right {

	@include bp('medium') {
		flex-flow: row-reverse;
	}
}

.c-image-feature__figure {
	flex: 0 0 50%;
	margin-bottom: $bsu;

	@include bp('medium') {
		margin-bottom: 0;
	}
}

.c-image-feature__body {
	text-align: center;
	width: 100%;

	@include bp('medium') {
		padding: $lsu;
	}

	> *:last-child,
	&:last-child {
		margin-bottom: 0;
	}
}

.c-image-feature__heading {
	@include font-24b;
	margin-bottom: 0.7em;
}

.c-image-feature__text {
	margin-bottom: $bsu;

	p {
		@include font-16;
	}

	*:last-child {
		margin-bottom: 0;
	}
}

.o-theme-dark {
	&.c-image-feature {
		background-color: $grey--dark;
	}

	.c-image-feature__heading,
	.c-image-feature__text {
		color: $white;
		a {
			color: $white;
			text-decoration: underline;
		}
	}
}
