// COMPONENT.PATHWAYS

.c-pathways {
	@include bp("large") {
		margin: $hsu 0;
	}
}

.c-pathways__item {
	height: 100%;

	@include flex-extend;
	flex-direction: column;

	box-shadow: 0 0 5px rgba($black, 0.1);
}

.c-pathways__head {
	color: $white;
	@include brand-bg;

	@include font-20b;

	padding: $bsu ($bsu + $ssu) $bsu ($bsu + $ssu);

	position: relative;
}
.c-pathways__item[href] {
	.c-pathways__head {
		padding-right: ($bsu + $ssu) + 20px + $ssu;
		&::after {
			@include font-icon;
			content: $iconf-arrow-right;

			@include abs-center-v;
			right: ($bsu + $ssu);
		}
	}
}

.c-pathways__image {}

.c-pathways__body {
	@include link-transition(background);
	@include font-16;
	flex-grow: 1;
	flex-shrink: 0;
	background: $white;
	color: $body-color;
	padding: $bsu ($bsu + $ssu);
}

.c-pathways__subtitle {
	display: block;
	margin-bottom: $ssu;
	@include font-16b;
}

.c-pathways__item {
	&[href] {
		&:hover,
		&:focus {
			.c-pathways__body {
				background: $grey--light;
			}
		}
	}
}
