// OBJECT.BTN

// Shared button styles

.o-btn {
	@include link-transition;
	display: inline-block;
	padding: ($ssu + $tsu);
	border-radius: 3px;
	border: 0;
	text-align: center;
	text-decoration: none;
	vertical-align: middle;
	cursor: pointer;

	@include font-16b;
}

.o-btn--full {
	display: block;
	width: 100%;
}

.o-btn--reset {
	padding: 0;
	border: 0;
	background: none;
}

.o-btn--brand {
	color: $white;
	background-color: $brand;

	&:hover,
	&:focus {
		background-color: $blue--light;
	}
}

.o-btn--secondary {
	color: $blue;
	background-color: $grey--light;

	&:hover,
	&:focus {
		background-color: $grey;
	}
}

.o-btn--small {
	padding: $ssu $bsu;
}

.o-btn--hollow {
	@include font-16b;
	color: $white;
	background-color: transparent;
	border: 1px solid $white;

	&:hover,
	&:focus {
		background-color: $white;
		color: $brand;
	}
}
