﻿// CONTROL.EMBED

.c-embed {}

/* whr embed hook */

#whr_embed_hook,
.c-embed script[src="https://www.workable.com/assets/embed.js"] ~ div[id] {
    @extend .o-body-text;
    margin: $bsu 0;

    @include bp("large") {
        margin: $lsu 0;
    }

    > ul {
        padding: 0;
        list-style: none;

        > li {
            list-style: none;
        }

        ul li {
            list-style-type: disc;
        }
    }

    // HM-24
    .whr-date {
        display: none;
    }
}

