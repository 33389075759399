﻿// CONTROL.EXPAND-COLLAPSE

.c-expand-collapse__item {
	@include ui-border('all');
	margin-bottom: $tsu;
}

.c-expand-collapse__item-header {
	@include link-transition;
	position: relative;
	padding: $ssu $bsu;
	padding-right: $bsu * 3;
	background-color: $white;
	cursor: pointer;
	outline: none;

	&:hover,
	&:focus {
		color: $brand;
	}
}

.c-expand-collapse__item-heading {
	@include font-16b;
}

.c-expand-collapse__item-icon {
	position: absolute;
	top: 50%;
	right: $bsu;
	transform: translateY(-50%);
	transition: transform 0.3s linear;

	.c-expand-collapse__item.is-expanded &,
	.c-expand-collapse__item[aria-expanded="true"] & {
		transform: translateY(-50%) rotate(180deg);
	}
}

.c-expand-collapse__item-body {
	padding: $ssu $bsu $bsu;

	.js & {
		display: none;
	}

	.c-expand-collapse__item.is-expanded &,
	.c-expand-collapse__item[aria-expanded="true"] & {
		display: block;
	}
}
