// COMPONENT.NAVIGATION

// Main Navigation
$navigation-bg: $white;

.c-navigation {
	position: relative;
	display: none;
	width: 100%;
	background-color: $navigation-bg;

	@include bp("large") {
		position: relative;
		display: block;
		align-self: flex-end;
		width: auto;
		background-color: transparent;
		box-shadow: none;
	}

	&[aria-expanded="true"] {
		display: block;
		position: absolute;
		top: $header-height--mobile;
		left: 0;

		box-shadow: 0 8px 6px -6px rgba($black, 0.2);
	}
}

.c-navigation__items {
	padding-top: $tsu;

	@include bp("large") {
		width: 100%;
		@include flex-extend;
		align-items: stretch;
		padding: 0;
	}
}

.c-navigation__item {
	position: relative;

	&:last-child {
		background: $white;
		padding: $bsu;
	}

	@include bp("large") {
		@include flex-extend;
		align-items: center;
		text-align: center;

		&:last-child {
			padding-right: 0;
			padding-top: 0;
			@include bp("extra-large") {
				margin-left: $hsu;
			}
		}
	}
}

.c-navigation__link {
	@include font-14-caps;
	font-weight: $weight--bold;
	display: block;
	padding: $ssu $hsu $ssu $bsu;
	color: $brand;

	@include bp("large") {
		display: inline-block;
		padding: $bsu $ssu $lsu $ssu;
		margin: 0 $ssu;
		white-space: nowrap;

		position: relative;
	}
}

@include bp("large") {
	.c-navigation__item[aria-current="true"] .c-navigation__link,
	.c-navigation__link:hover,
	.c-navigation__link:focus {
		&::after {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			border-bottom: 5px solid $brand--alt;
		}
	}
}

.c-navigation__link--home {
	font-size: rem(20px);

	@include bp("large") {
		line-height: 1;
	}
}

.c-navigation__button {}

.c-navigation__expander {
	position: absolute;
	right: $bsu * 1.5;
	top: $ssu + 2;

	@include bp("large") {
		//display: none;
		right: $ssu;
		top: 50%;
		transform: translateY(-50%);
	}
}

// Navigation Drop Down
.c-navigation-dropdown__items {
	display: none;
	padding: $ssu 0 $ssu $bsu;
	width: 100%;
	background: $navigation-bg;

	@include bp("large") {
		position: absolute;
		padding: 0;
		min-width: 250px;
		left: 50%;
		transform: translateX(-50%);
		box-shadow: 0 0 10px 0 rgba($black, 0.2);

		:focus-within > & { // :focus-within is not supported by IE
			display: block;
		}
	}

	[aria-expanded="true"] > &,
	.no-js :hover > & {
		display: block;
	}
}

.c-navigation-dropdown__item {
	display: inline-block;
	width: 100%;
	margin-bottom: $ssu;

	@include bp("large") {
		@include ui-border("bottom");
		margin: 0;
	}
}

.c-navigation-dropdown__link {
	@include font-14b;
	display: block;
	padding: $tsu 0;
	color: $body-color;

	&::before {
		display: none;
	}

	@include bp("large") {
		position: relative;
		padding: $ssu $lsu $ssu $bsu;

		&:hover {
			text-decoration: underline;
		}
	}
}

.c-navigation__skiplink {
	background-color: $red;
	color: $white;
	padding: 5px 10px;
	margin: 5px;
	position: fixed;
	top: 5px;
	left: 5px;
	z-index: 1000;
	border-radius: 5px !important;
	border: 1px solid $red;
	opacity: 0;
	transition: all 0.5s ease;

	&:focus {
		opacity: 1;
	}
}

// Language container - append around used to insert language picker in here when it's visible
.c-navigation__language {
	padding: $bsu;

	@include bp("medium") {
		display: none;
	}
}