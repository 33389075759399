﻿// CONTROL.FILTER(in Listing)

.c-filter {
	margin-bottom: $bsu;
}

.c-filter__form {

}

.c-filter__field {
	margin-bottom: $bsu;

	@include bp('large') {
		display: inline-flex;
		align-items: center;
	}
}

.c-filter__label {
	display: block;
	@include font-14b;
	margin-bottom: $ssu/2;

	@include bp('large') {
		margin-bottom: 0;
		margin-right: $ssu;
	}
}

.c-filter__dropdown {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	width: 100%;

	select {
		@include ui-border('all');
		padding: $ssu $lsu $ssu $ssu;
		margin: 0;
		width: 100%;
		background: $grey--light;
		/*Hiding the select arrow for firefox*/
		-moz-appearance: none;
		/*Hiding the select arrow for chrome*/
		-webkit-appearance: none;
		/*Hiding the select arrow default implementation*/
		appearance: none;

		&::-ms-expand {
			display: none;
		}
	}

	&:before {
		position: absolute;
		display: block;
		font-family: 'font-icons';
		content: "\e903";
		right: $bsu;
		top: 50%;
		transform: translateY(-50%);
		color: $brand;
		pointer-events: none;
	}

	@include bp('large') {
		width: auto;
		min-width: 200px;
		margin-right: $bsu;
	}
}
.c-filter__input-wrap {
	position: relative;
	
}

.c-filter__input {
	@include ui-border('all');
	padding: $ssu $lsu $ssu $ssu;
	margin: 0;
	width: 100%;
	background: $grey--light;
}

.c-filter__input-icon {
	position: absolute;
	right: $bsu;
	top: 50%;
	transform: translateY(-50%);
	color: $brand;
	pointer-events: none;
}

.c-filter__expand {
	.c-filter__expand-body {
		display: none;
	}

	.c-filter__heading {
		@include font-24b;
		cursor: pointer;
	}

	&[aria-expanded="true"] {
		.c-filter__expand-body {
			display: block;
		}

		.c-filter__heading {
			margin-bottom: 0.7em;
		}
	}

	@include bp('large') {
		.c-filter__expand-body {
			display: block;
		}

		.c-filter__heading {
			margin-bottom: 0.7em;
			cursor: default;
		}
	}
}

// filter in sidebar
.c-filter__aside {
	@include ui-border('all');



	.c-filter__form {
		padding: $bsu;
	}

	.c-filter__field {
		display: block;
		width: 100%;
	}

	.c-filter__dropdown {
		margin-right: 0;
		width: 100%;
	}

	.c-filter__label {
		margin-bottom: $ssu/2;
	}

	.c-filter__label-inline {
		display: inline-block;
		margin-top: $ssu;
		vertical-align: middle;
		@include font-14;
		width: 50px;
	}

	.c-filter__label-inline + .c-filter__input-wrap {
		width: calc(100% - 55px);
		display: inline-block;
		vertical-align: middle;
		margin-top: $ssu;
	}

	.c-filter__controls {
		@include flex-extend;
		flex-direction: row-reverse;
		justify-content: space-between;
		align-items: center;
	}
}

.c-filter__reset {
	color: $brand;
}
