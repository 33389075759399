﻿// CONTROL.HIGHLIGHTS
.c-highlights-pod {
	position: relative;
	margin-bottom: $bsu;
	border: 1px solid $grey;

	@include bp("small") {
		display: flex;
	}
}

.c-highlights__tag {
	@include font-12;
	position: absolute;
	left: 0;
	top: 0;
	background: $brand;
	z-index: 1;
	padding: 5px;
	color: $white;

}
.c-highlights__content {
	padding: $bsu;
	> p:last-child {
		margin-bottom: 0;
	}

	@include bp("small") {
		flex: 0 0 50%;
	}
}

.c-highlights__figure {
	@include bp("small") {
		flex: 0 0 50%;
	}
}

.c-highlights__title {
	@include font-24b;
	margin-bottom: $bsu;
}

.c-highlights__abstract {
	@include font-16;
}