﻿// THEME

$white: #fff;
$black: #000;

$grey--lightest: #f9f9f9;
$grey--light: #efefef;
$grey: #d4d4d4;
$grey--dark: #666;
$grey--darkest: #333;

$blue: #003052;
$blue--light: #0e609a;

$red: #a51e24;

$brand: $blue;
$brand--alt: $red;

// Shared non-theme colours, e.g. alert, errors
$success: #008000;
$success--light: lighten($success, 40);
$warning: #ffa500;
$warning--light: lighten($warning, 40);
$error: #f00;
$error--light: lighten($error, 40);

// Assign colours to common variables
$body-color: $black;
$base-ui-color: $grey;