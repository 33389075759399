﻿// CONTROL.IMAGE-GALLERY

.c-image-gallery__slides {
	@include list-reset;
}

.c-image-gallery__slide {

	&:nth-child(n+2) {
		display: none;

		.slick-initialized & {
			display: block;
		}
	}
}

.c-image-gallery__caption {
	@include flex-extend;
	justify-content: flex-end;
}

.c-image-gallery__caption-text {
	flex: 1 0 auto;
}

.c-image-gallery__caption-count {
	display: none;

	@include bp('medium') {
		display: inline;
		padding-left: $lsu;
		white-space: nowrap;
	}
}
