﻿// BASE.TYPOGRAPHY

// Config
//-------
// Base settings all set on the HTML element
// $base-font-size is used throughout to calculate em/rem sizes
$base-font-family: "Roboto", Arial, Helvetica, sans-serif;
$base-font-size: 16px;
$base-line-height: 22px;

$weight--light: 300;
$weight--normal: 400;
$weight--semibold: 600;
$weight--bold: 600;

// "Roboto"
// Light, light italic, regular, italic, bold, bold italic
// Apache 2.0 licence
@font-face {
	font-family: "Roboto";
	src: url("/content/fonts/roboto/roboto-light.eot");
	src: url("/content/fonts/roboto/roboto-light.eot?#iefix") format("embedded-opentype"),
	     url("/content/fonts/roboto/roboto-light.woff2") format("woff2"),
	     url("/content/fonts/roboto/roboto-light.woff") format("woff"),
	     url("/content/fonts/roboto/roboto-light.ttf") format("truetype"),
	     url("/content/fonts/roboto/roboto-light.svg") format("svg");
	font-style: normal;
	font-weight: $weight--light;
}

@font-face {
	font-family: "Roboto";
	src: url("/content/fonts/roboto/roboto-lightitalic.eot");
	src: url("/content/fonts/roboto/roboto-lightitalic.eot?#iefix") format("embedded-opentype"),
	     url("/content/fonts/roboto/roboto-lightitalic.woff2") format("woff2"),
	     url("/content/fonts/roboto/roboto-lightitalic.woff") format("woff"),
	     url("/content/fonts/roboto/roboto-lightitalic.ttf") format("truetype"),
	     url("/content/fonts/roboto/roboto-lightitalic.svg") format("svg");
	font-style: italic;
	font-weight: $weight--light;
}

@font-face {
	font-family: "Roboto";
	src: url("/content/fonts/roboto/roboto-regular.eot");
	src: url("/content/fonts/roboto/roboto-regular.eot?#iefix") format("embedded-opentype"),
	     url("/content/fonts/roboto/roboto-regular.woff2") format("woff2"),
	     url("/content/fonts/roboto/roboto-regular.woff") format("woff"),
	     url("/content/fonts/roboto/roboto-regular.ttf") format("truetype"),
	     url("/content/fonts/roboto/roboto-regular.svg") format("svg");
	font-style: normal;
	font-weight: $weight--normal;
}

@font-face {
	font-family: "Roboto";
	src: url("/content/fonts/roboto/roboto-italic.eot");
	src: url("/content/fonts/roboto/roboto-italic.eot?#iefix") format("embedded-opentype"),
	     url("/content/fonts/roboto/roboto-italic.woff2") format("woff2"),
	     url("/content/fonts/roboto/roboto-italic.woff") format("woff"),
	     url("/content/fonts/roboto/roboto-italic.ttf") format("truetype"),
	     url("/content/fonts/roboto/roboto-italic.svg") format("svg");
	font-style: italic;
	font-weight: $weight--normal;
}

@font-face {
	font-family: "Roboto";
	src: url("/content/fonts/roboto/roboto-bold.eot");
	src: url("/content/fonts/roboto/roboto-bold.eot?#iefix") format("embedded-opentype"),
	     url("/content/fonts/roboto/roboto-bold.woff2") format("woff2"),
	     url("/content/fonts/roboto/roboto-bold.woff") format("woff"),
	     url("/content/fonts/roboto/roboto-bold.ttf") format("truetype"),
	     url("/content/fonts/roboto/roboto-bold.svg") format("svg");
	font-style: normal;
	font-weight: $weight--bold;
}

@font-face {
	font-family: "Roboto";
	src: url("/content/fonts/roboto/roboto-bolditalic.eot");
	src: url("/content/fonts/roboto/roboto-bolditalic.eot?#iefix") format("embedded-opentype"),
	     url("/content/fonts/roboto/roboto-bolditalic.woff2") format("woff2"),
	     url("/content/fonts/roboto/roboto-bolditalic.woff") format("woff"),
	     url("/content/fonts/roboto/roboto-bolditalic.ttf") format("truetype"),
	     url("/content/fonts/roboto/roboto-bolditalic.svg") format("svg");
	font-style: italic;
	font-weight: $weight--bold;
}


// From Hind:

@mixin font-38 {
	font-size: 38px;
	line-height: 52px;
	font-weight: $weight--normal;
}

@mixin font-32 {
	font-size: 32px;
	line-height: 40px;
	font-weight: $weight--normal;
}

@mixin font-32b {
	font-size: 32px;
	line-height: 40px;
	font-weight: $weight--bold;
}

@mixin font-32bu {
	@include font-32b;
	text-transform: uppercase;
}

@mixin font-28b {
	font-size: 28px;
	line-height: 36px;
	font-weight: $weight--bold;
}

@mixin font-20b {
	font-size: 20px;
	line-height: 28px;
	font-weight: $weight--bold;
}

@mixin font-16 {
	font-size: 16px;
	line-height: 24px;
	font-weight: $weight--normal;
}
@mixin font-16b {
	font-size: 16px;
	line-height: 24px;
	font-weight: $weight--bold;
}

@mixin font-12bu {
	font-size: 12px;
	line-height: 17px;
	font-weight: $weight--bold;
	text-transform: uppercase;
}



// From FV:

@mixin font-36b {
	font-size: 36px;
	font-weight: $weight--bold;
	line-height: 1.15;
}

@mixin font-24 {
	font-size: 24px;
	font-weight: $weight--normal;
	line-height: 1.15;
}

@mixin font-24b {
	font-size: 24px;
	font-weight: $weight--bold;
	line-height: 1.15;
}

@mixin font-20 {
	font-size: 20px;
	font-weight: $weight--normal;
	line-height: 1.15;
}

@mixin font-20-tall {
	font-size: 20px;
	font-weight: $weight--normal;
	line-height: 1.4;
	letter-spacing: 0.01em;
}

@mixin font-18 {
	font-size: 18px;
	font-weight: $weight--normal;
	line-height: 1.35;
}

@mixin font-18b {
	font-size: 18px;
	font-weight: $weight--bold;
	line-height: 1.35;
}

@mixin font-16b {
	font-size: 16px;
	font-weight: $weight--bold;
	line-height: 24px;
	letter-spacing: 0.01em;
}

@mixin font-14 {
	font-size: 14px;
	font-weight: $weight--normal;
	line-height: 1.5;
}

@mixin font-14b {
	font-size: 14px;
	font-weight: $weight--bold;
	line-height: 1.5;
}

@mixin font-14-caps {
	font-size: 14px;
	font-weight: $weight--normal;
	text-transform: uppercase;
	line-height: 1.15;
}

@mixin font-12 {
	font-size: 12px;
	font-weight: $weight--normal;
	line-height: 1.15;
}

html {
	font-family: $base-font-family;
	color: $body-color;
}

p {
	margin-top: 0;
}
