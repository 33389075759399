// CONTROL.ROTATOR

.c-rotator__viewport {
	padding-bottom: percentage(394/1400);
}

.c-rotator__item {
	position: relative;
}

.c-rotator__body {
	position: absolute;
	left: $bsu;
	bottom: $bsu;
	padding: $bsu;
	color: $white;
	background: $brand;

	> *:last-child {
		margin-bottom: 0;
	}
}
