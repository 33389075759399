// OBJECT.CONTROL

.o-control {
	margin: $lsu 0;
}
.o-layout-main > .o-control:first-child {
	margin-top: 0;
}

.o-control__heading {
	@include font-32b;
	margin-bottom: $bsu;
}

.o-control__figcaption {
	@include ui-border(bottom);
	@include font-16;
	
	padding: $bsu 0;
}
