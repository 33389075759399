// COMPONENT.HERO

.c-hero {
	position: relative;
}

.c-hero__body {
	position: relative;

	margin-top: -$lsu;
	@include brand-bg;
	@include bp("large") {
		margin-top: 0;
		@include abs-center-v;
		max-width: 800px;
		margin-right: $hsu;

		background: rgba($blue--light, 0.75);

		&::before {
			content: "";
			position: absolute;
			top: 0;
			bottom: 0;
			right: 100%;
			width: 50vw;

			background: rgba($blue--light, 0.75);
		}
	}
}
.c-hero__body-text {
	color: $white;
	@include font-20b;
	@include bp("large") {
		@include font-32;
	}
	@include bp("extra-large") {
		@include font-38;
	}

	position: relative;
	margin-left: $lsu;
	padding: $lsu $hsu $lsu 0;

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 200px;
		max-width: 50%;
		border-top: 10px solid $red;
	}

	> p:last-child {
		margin-bottom: 0;
	}
}

// .c-hero__heading {
// 	@include font-36b;
// 	position: absolute;
// 	top: calc(50% + 32px);
// 	left: 50%;
// 	transform: translate(-50%, -50%);
// 	color: $white;
// 	text-shadow: 0 0 5px $black;
// }
