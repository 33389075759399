@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff2?5hngo3') format('woff2'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?5hngo3') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?5hngo3') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?5hngo3##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="iconf-"], [class*=" iconf-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconf-enlarge {
  &:before {
    content: $iconf-enlarge; 
  }
}
.iconf-home {
  &:before {
    content: $iconf-home; 
  }
}
.iconf-search {
  &:before {
    content: $iconf-search; 
  }
}
.iconf-arrow-up {
  &:before {
    content: $iconf-arrow-up; 
  }
}
.iconf-arrow-down {
  &:before {
    content: $iconf-arrow-down; 
  }
}
.iconf-arrow-left {
  &:before {
    content: $iconf-arrow-left; 
  }
}
.iconf-arrow-right {
  &:before {
    content: $iconf-arrow-right; 
  }
}
.iconf-close {
  &:before {
    content: $iconf-close; 
  }
}
.iconf-minus {
  &:before {
    content: $iconf-minus; 
  }
}
.iconf-plus {
  &:before {
    content: $iconf-plus; 
  }
}
.iconf-tick {
  &:before {
    content: $iconf-tick; 
  }
}
.iconf-link {
  &:before {
    content: $iconf-link; 
  }
}
.iconf-external {
  &:before {
    content: $iconf-external; 
  }
}
.iconf-download {
  &:before {
    content: $iconf-download; 
  }
}
.iconf-photo {
  &:before {
    content: $iconf-photo; 
  }
}
.iconf-camera {
  &:before {
    content: $iconf-camera; 
  }
}
.iconf-video {
  &:before {
    content: $iconf-video; 
  }
}
.iconf-play {
  &:before {
    content: $iconf-play; 
  }
}
.iconf-email {
  &:before {
    content: $iconf-email; 
  }
}
.iconf-print {
  &:before {
    content: $iconf-print; 
  }
}
.iconf-phone {
  &:before {
    content: $iconf-phone; 
  }
}
.iconf-fax {
  &:before {
    content: $iconf-fax; 
  }
}
.iconf-address {
  &:before {
    content: $iconf-address; 
  }
}
.iconf-google-maps {
  &:before {
    content: $iconf-google-maps; 
  }
}
.iconf-calendar {
  &:before {
    content: $iconf-calendar; 
  }
}
.iconf-share {
  &:before {
    content: $iconf-share; 
  }
}
.iconf-facebook {
  &:before {
    content: $iconf-facebook; 
  }
}
.iconf-twitter {
  &:before {
    content: $iconf-twitter; 
  }
}
.iconf-youtube {
  &:before {
    content: $iconf-youtube; 
  }
}
.iconf-flickr {
  &:before {
    content: $iconf-flickr; 
  }
}
.iconf-pinterest {
  &:before {
    content: $iconf-pinterest; 
  }
}
.iconf-instagram {
  &:before {
    content: $iconf-instagram; 
  }
}
.iconf-linkedin {
  &:before {
    content: $iconf-linkedin; 
  }
}
.iconf-weibo {
  &:before {
    content: $iconf-weibo; 
  }
}
.iconf-wechat {
  &:before {
    content: $iconf-wechat; 
  }
}
.iconf-youku {
  &:before {
    content: $iconf-youku; 
  }
}
.iconf-snapchat {
  &:before {
    content: $iconf-snapchat; 
  }
}
.iconf-blogger {
  &:before {
    content: $iconf-blogger; 
  }
}
.iconf-tumblr {
  &:before {
    content: $iconf-tumblr; 
  }
}
.iconf-tripadvisor {
  &:before {
    content: $iconf-tripadvisor; 
  }
}
.iconf-web {
  &:before {
    content: $iconf-web; 
  }
}
.iconf-chat {
  &:before {
    content: $iconf-chat; 
  }
}
.iconf-filter {
  &:before {
    content: $iconf-filter; 
  }
}
.iconf-reload {
  &:before {
    content: $iconf-reload; 
  }
}
.iconf-sort {
  &:before {
    content: $iconf-sort; 
  }
}

