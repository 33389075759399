// COMPONENT.HEADER

$header-height--mobile: 65px;
$header-height--desktop: 100px;

.c-header {
	position: relative;
	z-index: $z-header;
	height: $header-height--mobile;
	width: 100%;
	background-color: $white;
	box-shadow: 0 8px 6px -6px rgba($black, 0.2);
	transition: all 0.25s ease-in-out;

	@include bp("large") {
		height: $header-height--desktop;
	}
}

.c-header__wrapper {
	@include flex-extend;
	position: relative;
	align-items: stretch;
	justify-content: space-between;

	height: 100%;

	@include layout-wrapper;
}

.c-header__logo {
	@include flex-extend;
	align-items: center;
	padding: $ssu $ssu $ssu 0;
	text-align: center;

	img {
		width: 80px;
		@include bp("large") {
			width: 100px;
		}
	}
}

.c-header__tools {
	@include flex-extend;
	flex: 1 0 auto;
	align-items: center;
	justify-content: flex-end;
	order: 3;
	padding-right: $bsu;

	@include bp("large") {
		display: none;
	}
}

// Language
.c-header__language {
	display: none;

	@include bp("medium") {
		display: block;
		padding-right: $bsu;
	}
}

.c-header__language-dropdown {
	@include font-16;
	display: block;
	padding: 0.65em;
	border: 1px solid $grey;
}


// Mobile buttons
$mobile-button-size: 45px;
$mobile-button-color: $brand;
$mobile-button--hover-color: darken($brand, 15);
$mobile-button-font-size: 25px;
$mobile-button-padding: ($mobile-button-size - $mobile-button-font-size) / 2;

.c-header__mobile-buttons {
	flex: 1 0 auto;
	text-align: right;

	@include bp("medium") {
		flex: 0 0 auto;
	}
}

.c-header__mobile-button {
	position: relative;
	width: $mobile-button-size;
	height: $mobile-button-size;
	padding: $mobile-button-padding 0;
	border: 0;
	background: none;
	transition: all 0.3s ease-in-out;
	cursor: pointer;
}

// Nav Toggle
.c-header__nav-toggle {

	@include bp("large") {
		display: none;
	}

	b {
		transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out, border-color 0.3s ease-in-out;
		position: absolute;
		left: $mobile-button-padding;
		display: block;
		width: $mobile-button-font-size;
		height: 0;
		border-top: 1px solid $mobile-button-color;

		&:nth-child(1) { top: $mobile-button-padding; }
		&:nth-child(2) { top: $mobile-button-padding + 12px; }
		&:nth-child(3) { top: $mobile-button-padding + 24px; }
	}

	&[aria-expanded="true"] {

		b {
			&:nth-child(1) { transform: translateY(12px) rotate(45deg); }
			&:nth-child(2) { opacity: 0; }
			&:nth-child(3) { transform: translateY(-12px) rotate(-45deg); }
		}
	}

	&:hover,
	&[aria-expanded="true"] {
		b {
			border-color: $mobile-button--hover-color;
		}
	}
}

// Search Toggle
.c-header__search-toggle {
	font-size: $mobile-button-font-size;
	color: $mobile-button-color;
	cursor: pointer;

	&:hover,
	&[aria-expanded="true"] {
		color: $mobile-button--hover-color;
	}

	&[aria-expanded="true"] .iconf-search {
		&:before {
			content: $iconf-close;
		}
	}

	.iconf-search {
		position: absolute;
		left: $mobile-button-padding;
		top: $mobile-button-padding;
	}
}

.c-header__search {
	display: none;
	position: absolute;
	background: $white;
	top: $header-height--mobile;
	left: 0;
	width: 100%;
	height: calc(100vh - #{$header-height--mobile});
	box-shadow: 0 8px 6px -6px rgba(0,0,0,0.2);

	@include bp("large") {
		position: absolute;
		left: 0;
		top: $header-height--desktop;
		height: auto;
	}
}

.c-header__search-wrapper {
	width: 65%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	padding-bottom: 20px;

	@include bp("large") {
		width: 700px;
		position: static;
		padding: 50px 0 80px;
		margin: 0 auto;
		top: auto;
		left: auto;
		transform: none;
	}
}

.c-header__search-field {
	position: relative;
}

.c-header__search-input {
	@include font-16;
	@include placeholder-text($grey);
	display: block;
	padding: 0.3em 0.5em;
	width: 100%;
	margin: 0 auto;
	border: none;
	border-bottom: 1px solid $grey;
	border-radius: 0;
	background: transparent;
}

.c-header__search-btn {
	position: absolute;
	padding: 0;
	width: 50px;
	height: 50px;
	border: none;
	background: 0 0;
	right: 0;
	top: -11px;
	font-size: 26px;
	line-height: 1;
	color: $brand;
	cursor: pointer;

	&:before {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	&:hover {
		color: darken($brand, 10%);
	}
}