﻿// CONTROL.CTA-VERBOSE

@include bp("medium") {
	.c-cta-verbose__body {
		@include flex-extend;
		align-items: center;
		text-align: left;
	}
}

.c-cta-verbose__body-wrapper {
	margin-bottom: $bsu;

	> *:last-child {
		margin-bottom: 0;
	}
}

@include bp("medium") {
	.c-cta-verbose__body-wrapper {
		flex: 1 1 auto;
		padding-right: $lsu;
		margin-bottom: 0;
	}
}

.c-cta-verbose__text {
	margin-bottom: 2em;
}

.c-cta-verbose__link {
	@include bp("medium") {
		white-space: nowrap;
	}
}
