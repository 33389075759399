/* OBJECT.LAYOUT */

.o-layout-wrapper {
	@include layout-wrapper;

	// Remove padding on sides if a layout-wrapper is nested within another layout-wrapper
	.o-layout-wrapper {
		padding-left: 0;
		padding-right: 0;
	}
}

.o-layout-wrapper--narrow {
	max-width: $layout-wrapper-narrow-width;
}

.o-layout-page {
	position: relative;
	z-index: $z-page;
	padding-top: $lsu;
	@include bp("large") {
		padding-top: $hsu;
	}
}

.o-layout-content--has-aside {

	@include bp('large') {
		@include flex-extend;
	}
}

.o-layout-main {
	display: block;

	.o-layout-content--has-aside & {

		@include bp('large') {
			width: calc(100% - #{$layout-aside-width + $layout-aside-gutter-width});
		}
	}
}

.o-layout-aside {
	margin-top: $lsu;

	@include bp('large') {
		width: $layout-aside-width + $layout-aside-gutter-width;
		padding-left: $layout-aside-gutter-width;
		margin-top: 0;

		&.is-left {
			padding-left: 0;
			padding-right: $layout-aside-gutter-width;
		}
	}
}

.o-layout-header {
	margin-bottom: $lsu;
	@include bp("large") {
		margin-bottom: $hsu;
	}
	text-align: center;

	> *:last-child {
		margin-bottom: 0;
	}
}

.o-layout-header__heading {
	display: inline-block;

	@include font-36b;
	padding-bottom: $bsu;
	margin-bottom: $bsu;

	position: relative;
	&::after {
		content: "";
		@include abs-center-h;
		bottom: 0;

		border-bottom: 5px solid $red;
		width: 80%;
		max-width: 150px;
	}
}

.o-layout-header__lede {
	@include font-20-tall;
	max-width: $layout-wrapper-narrow-width;
	margin: $lsu auto 0;
	text-align: left;

	@include bp("large") {
		width: 80%;
	}

	a {
		@include link-brand;
	}

	> *:last-child {
		margin-bottom: 0;
	}
}

.o-layout-header__date {
	@include font-12;
	margin-bottom: 2em;
}