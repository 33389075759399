﻿// CONTROL.VIDEO

.c-video-playlist__player {
	margin-bottom: $bsu;
}

.c-video-playlist__embed {
	padding-bottom: percentage(9/16);
}

.c-video-playlist__cover {
	width: 100%;
}

.c-video-playlist__icon {
	@include abs-center;
	font-size: 40px;
	color: $white;

	@include bp('small') {
		font-size: 60px;
	}

	@include bp('medium') {
		font-size: 80px;
	}
}
