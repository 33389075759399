﻿// CONTROL.VIDEO

.c-video__embed {
	padding-bottom: percentage(9/16);
}

.c-video__cover {
	@include abs-center;
	width: 100%;
}

.c-video__icon {
	@include abs-center;
	font-size: 40px;
	color: $white;

	@include bp('small') {
		font-size: 60px;
	}

	@include bp('medium') {
		font-size: 80px;
	}
}
