// COMPONENT.FOOTER

$footer-logo-width: 150px;

.c-footer {
	position: relative;
	background-color: $grey;

	padding: $bsu 0;
}

.c-footer__columns {
	@include flex-extend;
	flex-wrap: wrap;

	@include bp("large") {
		flex-wrap: nowrap;
		justify-content: space-between;
		align-items: stretch;
	}
}

.c-footer__column {
	@include flex-extend;
	align-items: center;
	flex-wrap: wrap;

	margin-top: $ssu;
	&:first-child {
		margin-top: 0;
	}

	width: 100%;
	@include bp("large") {
		margin-top: 0;
		width: 50%;
		justify-content: flex-start;
		&:last-child {
			justify-content: flex-end;
		}
	}
}

.c-footer__navigation {
	column-count: 2;
	column-gap: $lsu;

	@include bp("medium") {
		flex: 0 0 auto;
		width: calc(50% - #{$footer-logo-width / 2});
		padding-left: $lsu;
		margin-bottom: 0;
	}

	@include bp("large") {
		width: calc(75% - #{$footer-logo-width});
		padding-left: $hsu;
	}
}

.c-footer__navigation-link {
	@include font-16;
	display: inline-block;
	padding: $tsu 0;
	outline: none;

	@include link-brand;
}

.c-footer__social {
	margin-top: $tsu;
}

.c-footer__social-list {
	@include flex-extend;
	flex-wrap: wrap;
}

.c-footer__social-item {
	margin-right: $tsu;
	margin-bottom: $tsu;
}

.c-footer__social-link {
	@include square(40px);
	@include link-transition;
	font-size: 20px;
	color: $brand;
	background-color: transparent;

	&:hover,
	&:focus {
		color: $white;
		background-color: $brand;
	}
}

.c-footer__legal {
	@include flex-extend;
	align-items: center;
	flex-wrap: wrap;

	@include bp("medium") {
		margin-top: $ssu;
	}
}

.c-footer__legal-copyright {
	@include font-12;
	display: block;
	margin-bottom: $ssu;
	margin-right: $bsu;
	color: $body-color;
}

.c-footer__legal-links {
	@include flex-extend;
	align-items: center;
	margin-bottom: $ssu;
	margin-right: $bsu;
}

.c-footer__legal-links-item {
	@include font-12;
	display: inline-block;
	padding: 0 $ssu 0 0;
}

.c-footer__legal-link {
	@include font-12;
	color: $body-color;
	outline: none;

	&:focus,
	&:hover {
		text-decoration: underline;
	}
}

.c-footer__tb {
	@include font-12;
	display: block;
	margin-bottom: $ssu;
	text-align: center;
	color: $body-color;
	outline: none;

	&:focus,
	&:hover {
		text-decoration: underline;
	}
}

.c-footer-back-to-top {
	display: block;
	position: fixed;
	bottom: 30px;
	right: 0;
	padding: $ssu 0;
	z-index: $z-back-to-top;
	width: 45px;
	height: 45px;
	color: $white;
	background: $brand;
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.5s linear,visibility 0s linear 0.5s;

	&:before {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
	}

	&.fade-in {
		opacity: 1;
		visibility: visible;
		transition-delay: 0s;
	}

	@include bp("large") {
		position: relative;
		display: none;
		bottom: 0;
		vertical-align: bottom;
		background: transparent;
		color: $grey--darkest;
		border: 1px solid $grey--darkest;
		border-bottom: 0;
		height: 30px;
		opacity: 1;
		visibility: visible;

		&.sticky-on-desktop {
			display: inline-block;
			opacity: 0;
			visibility: hidden;

			&.fade-in {
				position: fixed;
				bottom: auto;
				top: 50%;
				transform: translateY(-50%);
				width: 45px;
				height: 45px;
				color: $white;
				border: none;
				background: $brand;
				opacity: 1;
				visibility: visible;
			}
		}
	}
}
