// CONTROL.RTE

.mce-content-body,
.c-rte {
	@include clearfix;

	& + & {
		margin-top: $bsu;
	}
}

// RTE figure - shared styling across macros
.c-rte__figure {
	margin: $bsu auto;

	&.u-float--left {
		clear: left;

		@include bp('small') {
			margin-top: 0;
			margin-right: $bsu;
			margin-left: 0;
		}
	}

	&.u-float--right {
		clear: right;

		@include bp('small') {
			margin-top: 0;
			margin-right: 0;
			margin-left: $bsu;
		}
	}
}

.c-rte__figcaption {
	@include ui-border('bottom');
	padding: $ssu 0;
}
