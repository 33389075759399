﻿// CONTROL.FORM

.c-form {

	[type="text"],
	[type="password"],
	[type="email"],
	[type="tel"],
	select,
	textarea {
		@include font-16;
		display: block;
		width: 100%;
		padding: 0.65em;
		border: 1px solid $grey;

		&.input-validation-error {
			border-color: $error;
			margin-bottom: $tsu;
		}
	}

	select ~ .field-validation-error {
		border-color: $error;
		margin-bottom: $tsu;
	}

	textarea {
		height: 150px;
	}
}
.c-form__field {
	margin-bottom: $bsu;

	&:last-child {
		margin-bottom: 0;
	}
}
.c-form__field-label {
	@include font-16;
}
.c-form__required-indicator {
	color: $red;
}

.c-form__controls {
	margin-top: $bsu;
}

.umbraco-forms-caption {
	@include font-18;
	margin-bottom: 1.5em;
}

.umbraco-forms-fieldset {
	@include ui-border('bottom');
	padding-bottom: $lsu;
	margin-bottom: $lsu;
}

.umbraco-forms-field {
	margin-bottom: $bsu;

	&:last-child {
		margin-bottom: 0;
	}

	&.checkbox {
		position: relative;
		padding-left: $bsu;

		input[type="checkbox"]{
			position: absolute;
			left: 0;
			top: $tsu;

		}
	}
}

.umbraco-forms-label-wrapper {
	order: 2;
}

.umbraco-forms-label, .c-form__label {
	@include font-18;
	display: block;
}

.umbraco-forms-tooltip {
	@include font-14;
	color: $grey--dark;
}

.field-validation-error, .c-form__error-message {
	@include font-14;
	color: $error;
}
.c-form__error-message {
	display: none;
}
.has-error .c-form__error-message {
	display: block;
}

.umbraco-forms-field-wrapper {
	margin-top: $ssu;


	.shortanswer & {

		@include bp('medium') {
			width: 100%;
			max-width: 500px;
		}
	}

	.dropdown & {

		@include bp('medium') {
			width: 100%;
			max-width: 500px;
		}
	}

	.date & {
		position: relative;
		width: 75%;

		&:after {
			position: absolute;
			display: block;
			right: $ssu;
			top: 15px;
			font-family: #{$icomoon-font-family};
			content: $iconf-calendar;
			pointer-events: none;
		}

		@include bp('medium') {
			width: 25%;
		}
	}

	.checkbox & {
		margin-top: 0;
	}

	.singlecheckbox & {
		order: -1;
		margin-right: $tsu;
	}

	.titleanddescription & {
		margin-top: 0;
	}

	.checkboxlist, .radiobuttonlist {
		label {
			display: inline-block;
			margin-bottom: $ssu/2;
			margin-left: 3px;
		}

		label:last-of-type {
			margin-bottom: 0;
		}
	}
}

.umbraco-forms-navigation {
	text-align: right;
}


.titleanddescription {

	h2 {
		@include font-32;
		margin-bottom: 0.5em;
	}

	p {
		@include font-20;

		&:last-child {
			margin-bottom: 0;
		}
	}
}


// Fake inputs
.c-fake-checkbox {

	&:checked ~ .c-fake-checkbox__display {

		&:before {
			border-color: $blue;
		}
	}
}

.c-fake-checkbox__display {

	&:before {
		@include link-transition;
		content: '';
		position: absolute;
		left: 4px;
		top: 6px;
		display: block;
		width: 16px;
		height: 8px;
		border: 2px solid transparent;
		border-width: 0 0 2px 2px;
		background: transparent;
		transform: rotate(-45deg);
	}
}

// Fake Radio
.c-fake-radio {

	&:checked ~ .c-fake-radio__display {
		&:before {
			background-color: $blue;
		}
	}
}

.c-fake-radio__display {
	border-radius: 25px;

	&:before {
		@include link-transition;
		content: '';
		position: absolute;
		left: 3px;
		top: 3px;
		display: block;
		width: 17px;
		height: 17px;
		border-radius: 17px;
		background-color: transparent;
	}
}